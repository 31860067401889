import React from "react"
import styles from "../components/Blog/blog.module.css"
import { graphql } from "gatsby"
import Title from "../components/Title/Title"
import BlogPost from "../components/Blog/BlogPost"
import Layout from "../components/layout/Layout"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import SEO from "../components/SEO"
import Image from 'gatsby-image'

const BlogListTemplate = (props) => {
  const { currentPage, numPages } = props.pageContext
  const isFirst = currentPage === 1
  const isLast = currentPage === numPages
  const prevPage = currentPage - 1 === 1 ? `/blog` : `/blog/${currentPage - 1}`
  const nextPage = `/blog/${currentPage + 1}`
  const { data } = props

  if (data.posts.edges.length > 1) {
    return (
      <Layout>
        <SEO title='Blog' description='Nolan James Blog Posts'/>
        <section className={styles.blog}>
          <Title title='Latest' subtitle='Posts'/>
          <div className={styles.center}>
            {data.posts.edges.map(({ node }) => {
              return <BlogPost key={node.id} blog={node}/>
            })}
          </div>
          <section className={styles.links}>
            {
              !isFirst &&
              <AniLink fade to={prevPage} className={styles.link}>
                Prev
              </AniLink>
            }
            {Array.from({ length: numPages }, (_, i) => {
              return <AniLink key={i} fade to={`/blog/${i === 0 ? "" : i + 1}`}
                              className={i + 1 === currentPage ? `${styles.link} ${styles.active}` : `${styles.link}`}>
                {i + 1}
              </AniLink>
            })}
            {
              !isLast &&
              <AniLink fade to={nextPage} className={styles.link}>
                Next
              </AniLink>
            }
          </section>
        </section>
      </Layout>
    )
  } else {
    return (
      <Layout>
        <SEO title='Blog' description='Nolan James Blog Posts'/>
        <section className={styles.blog}>
          <Title title='Latest' subtitle='Posts'/>
          <div className={styles.centerEmpty}>
            <Image fluid={data.emptyImage.childImageSharp.fluid} className={styles.emptyImg}/>
            <p className={styles.emptyText}>Check back soon for content</p>
          </div>
        </section>
      </Layout>
    )
  }

}

export const query = graphql`
    query getPosts($skip: Int!, $limit: Int!) {
        posts: allContentfulBlog(skip: $skip, limit: $limit, sort:{fields: createdAt, order: DESC} filter: {title: {ne: "TEST TEMPLATE"}}) {
            edges {
                node {
                    slug
                    title
                    id: contentful_id
                    createdAt(formatString: "Do MMMM, Y")
                    featuredImage {
                        fluid {
                            ...GatsbyContentfulFluid_withWebp
                        }
                    }
                }
            }
        }
        emptyImage:file(relativePath: {eq: "empty-blog.jpg"}) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid

                }
            }
        }
    }

`

export default BlogListTemplate
