import React from "react"
import styles from './blog-post.module.css'
import Image from 'gatsby-image'
import AniLink from 'gatsby-plugin-transition-link'

const BlogPost = ({blog}) => {
  const {slug, title, featuredImage, createdAt} = blog

  return (
    <article className={styles.blog}>
      <div className={styles.imgContainer}>
        <Image fluid={featuredImage.fluid} className={styles.img} alt='blog' />
        <AniLink fade to={`/blog/${slug}`} className={styles.link}>Read More...</AniLink>
        <h6 className={styles.date}>{createdAt}</h6>
      </div>
      <div className={styles.footer}>
        <h4>{title}</h4>
      </div>
    </article>
  )
}

export default BlogPost
